import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {FaPhone} from "react-icons/fa";
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'


emailjs.init("GgjMnXz5r2fp6pzgF");

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    const emailData = {
      from_name: e.target.name.value,
      from_email: e.target.email.value,
      message: e.target.message.value,
      to_email: "abrarhq5@gmail.com",
    };
  
    emailjs.send('service_8nj9ii8', 'template_54xgiji', emailData, 'GgjMnXz5r2fp6pzgF')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  
    e.target.reset();
  };
  

  return (
    <section id='contact'>
      <h5>Let's have a chat!</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>abrarhq5@gmail.com</h5>
            <a href="mailto:abrarhq5@gmail.com" target="_blank">Send a message</a>
          </article>
          {/*<article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>egatortutorials</h5>
            <a href="https://m.me/ernest.achiever" target="_blank">Send a message</a>
  </article>*/}
          <article className="contact__option">
            <FaPhone className='contact__option-icon'/>
            <h4>Phone Number</h4>
            <h5>+14049106217</h5>
            <a href="sms:+14049106217" target="_blank">Send a message</a>
            </article>
</div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact