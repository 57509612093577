
import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import {GiAbstract007} from "react-icons/gi";
import { SiDashlane } from "react-icons/si";
import { SiTwitter} from "react-icons/si";
import { SiGeneralmotors} from "react-icons/si";
import { MdCodeOff } from "react-icons/md";
import { RiShieldFlashFill } from "react-icons/ri";






function Experience3() {
    return (
      <section id='timeline' >
      <div className="experience">
      
      <h2 style={{ color: '#FFF', textAlign: 'center', fontSize: '2.0rem', marginBottom: '2rem' }}>My Professional Timeline</h2>
      
        <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#05386b', color: '#EDF5E1' }}
    contentArrowStyle={{ borderRight: '7px solid  #05386b' }}
    date= "August 2019 - December 2019"
    iconStyle={{ background: '#05386b', color: '#EDF5E1' }}
    icon={<SiDashlane />}
  >
    <h3 className="vertical-timeline-element-title">Data Analyst Intern at Dashlane</h3>
    <h4 className="vertical-timeline-element-subtitle">Atlanta, GA</h4>
    <p>
    Created pipelines, improved data accuracy for diverse sources, reduced errors, revamped UI
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
     className="vertical-timeline-element--education"
     contentStyle={{ background: '#379683', color: '#EDF5E1' }}
     contentArrowStyle={{ borderRight: '7px solid  #379683' }}
     date="August 2020 – January 2021"
     iconStyle={{ background: '#379683', color: '#EDF5E1' }}
    icon={<MdCodeOff />}
  >
    <h3 className="vertical-timeline-element-title">Project Assistant for GT College of Computing</h3>
    <h4 className="vertical-timeline-element-subtitle">Atlanta, GA</h4>
    <p>
    Report analyzed Naive Bayes NLP for $20M extraction industry niche
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ background: '#5CDB95' }}
  contentArrowStyle={{ borderRight: '7px solid #5CDB95' }}
  iconStyle={{ background: '#5CDB95', color: '#EDF5E1' }}
  icon={<GiAbstract007 />}
>
  <div style={{ color: '#000' }}>
    <h3 className="vertical-timeline-element-title">Georgia Tech Commencement</h3>
    <h4 className="vertical-timeline-element-subtitle">Atlanta, GA</h4>
    <p>
    Graduated from Georgia Tech in 2021 with B.S in Business Administration and a Minor in Computer Science
    </p>
  </div>
  <div className="vertical-timeline-element-date" style={{ color: '#EDF5E1' }}>Spring 2021</div>
</VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#05386b', color: '#EDF5E1' }}
    contentArrowStyle={{ bosrderRight: '7px solid  #05386b' }}
    date="January 2022 - April 2023"
    iconStyle={{  background: '#05386b', color: '#EDF5E1'}}
    icon={<SiGeneralmotors />}
  >
    <h3 className="vertical-timeline-element-title">Software Developer at General Motors</h3>
    <h4 className="vertical-timeline-element-subtitle">Atlanta, GA</h4>
    <p>
    Deployed SAP EIS, developed APIs, implemented software, upgraded technology, consulted clients to optimize inventory.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: '#379683', color: '#EDF5E1' }}
    contentArrowStyle={{ borderRight: '7px solid  #379683' }}
    date="May 2023 - Present"
    iconStyle={{ background: '#379683', color: '#EDF5E1' }}
    icon={<RiShieldFlashFill />}
  >
    <h3 className="vertical-timeline-element-title">Software Engineer at Optimed Health</h3>
    <h4 className="vertical-timeline-element-subtitle">Atlanta, GA</h4>
    <p>
    Crafted Scala/Java backends, React frontends, integrating MySQL, leveraging Firebase. Deploying scalable apps on GCP, prioritizing user experience.
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
  className="vertical-timeline-element--education"
  contentStyle={{ background: '#5CDB95', color: '#EDF5E1' }}
  contentArrowStyle={{ borderRight: '7px solid #5CDB95' }}
  date="August 2023 - Present"
  iconStyle={{ background: '#5CDB95', color: '#EDF5E1' }}
  icon={<GiAbstract007 />}
>
  <div style={{ color: '#000' }}>
    <h3 className="vertical-timeline-element-title">Master's in Computer Science with a Concentration in Artificial Intelligence</h3>
    <h4 className="vertical-timeline-element-subtitle">Georgia Tech, Atlanta, GA</h4>
    <p>
      Currently pursuing advanced coursework in Artificial Intelligence, Machine Learning, and Data Analysis.
    </p>
  </div>
</VerticalTimelineElement>
  
  
  
</VerticalTimeline>
      </div>
      </section>
    );
  }
  
  export default Experience3;