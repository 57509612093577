import React from 'react'
import CV from '../../assets/Abrar_Hoque_Resume.pdf'
import { Link } from 'react-router-dom';

const CTA = () => {

  const navigateToExperience = () => {
    window.location.href = "src/components/pages/Experience.js";
  };

  return (
    <div className='cta'>

        <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        <a download className='btn'>Download Resume</a>
        
       {/*<a onClick={navigateToExperience} className='btn btn-primary'>Experience</a>*/}
        {/*<Link to="src/components/pages/Experience.js" className='btn btn-primary'>Experience</Link>*/}
    </div>
  )
}

export default CTA