import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>My experiences</h5>
      <h2>Employment summary</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Data Analyst at Dashlane</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Swift Program for encryption of 100k passwords from SQL to MongoDB</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Analyzed user data for behavior patterns resulting in 10% engagement increase</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Built and maintained dashboards to communicate key metrics to stakeholders</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Developed data pipelines from diverse sources</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Reduced data errors by 15% through accurate and complete data structuring</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Project Assistant for GT College of Computing</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Produced a 10-page report on Naive Bayes NLP implementation in the $20M information extraction industry</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Conducted research to determine the best niche for market entry using Naive Bayes NLP</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Analyzed industry trends and data to inform decision-making</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Demonstrated proficiency in research, analysis, and technical writing</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Software Developer at General Motors</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Deployed SAP EIS for inventory transfer resulting in 34% optimization for GM</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Developed Java Spring Boot RESTful APIs for tracking inventory changes resulting in 26% fewer order errors</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Installed Quanta Software with Andon Solutions for increased data collection</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Upgraded OSWAT software to Angular for 16% faster website runtime during PROD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              
              <p>Met with clients and developers to determine technologies and design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Developed MVP for PCF apps</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services