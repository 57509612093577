import React from 'react'
import './about.css'
import ME from '../../assets/me-about3.jpg'
import ME2 from '../../assets/me15.JPG'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Here's a quick intro</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME2} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>2+ Years Working</small>
              <br />
              <small><strong>Work Authorization:</strong> US Citizen</small>
            </article>

           {} <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Education</h5>
              <small>B.S. Business Administration, Georgia Tech</small>
           <br />
              <small>Pursuing M.S. in Computer Science, focus on AI, Georgia Tech</small>
              
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>

          <p>
    I am currently a Software Engineer at OptiMedHealth and am also pursuing a Master's in Computer Science, specializing in Artificial Intelligence. Prior to this, I earned a B.S. in Business Administration with a Minor in Computer Science from Georgia Tech in 2021.

    I am a highly motivated professional with a strong passion for problem-solving at the intersection of strategy and technology. With expertise in programming and data analysis, I continuously seek opportunities to enhance my skill set and contribute to innovative organizations. My strong communication skills and collaborative mindset equip me well to tackle new challenges in the fields of software engineering and data analysis.
</p>


          <a href="#contact" className='btn btn-primary'>Let's Connect</a>
        </div>
      </div>
    </section>
  )
}

export default About