import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Experience2 from './components/pages/Experience2.jsx'
import Experience3 from './components/pages/Experience3.jsx'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import { useEffect } from 'react';



const App = () => {

  useEffect(() => {
    // Load the gtag script dynamically
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-G9LQFSK1HE';
    script.async = true;
    document.body.appendChild(script);

    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Initialize Google Analytics tracking
    gtag('js', new Date());
    gtag('config', 'G-G9LQFSK1HE');

    // Clean up the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <>
        <Header />
        <Nav />
        <About />
        <Experience />

        <Experience3 />
        {<Services />}
        
        <Portfolio />
        <Testimonials />
        <Contact />
        <Footer />
    </>
  )
}

export default App