import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import { GiAbstract007 } from "react-icons/gi";

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Abrar Hoque</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Skillsets</a></li>
        <li><a href="#timeline">TimeLine</a></li>
        <li><a href="#services">Experiences</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
      {/*  <a href="https://facebook.com"><FaFacebookF/></a>
        <a href="https://instagram.com"><FiInstagram/></a> */}
      <a href="https://twitter.com/zone_astronomy"><IoLogoTwitter/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Copyright 2023 Abrar Hoque</small>
      </div>
    </footer>
  )
}

export default Footer