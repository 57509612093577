import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar5.jpg'
import AVTR2 from '../../assets/avatar6.jpg'
import AVTR3 from '../../assets/avatar7.jpg'
import AVTR4 from '../../assets/avatar8.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR1,
    name: 'Jacob Henderson',
    review: "Abrar's a rockstar problem solver & team player! At GM, he crushed it with SAP EIS, mastering Java Spring Boot for RESTful APIs & Quanta. Loved working with him - he's always going the extra mile!"
  },
  {
    avatar: AVTR2,
    name: 'Jay Taylor',
    review: "Abrar's energy is contagious and it is capable of transforming the work environment into something anyone can look forward to"
  },
  {
    avatar: AVTR3,
    name: 'Jared Deshpande',
    review: "During Abrar's internship at Dashlane, Abrar was responsible for developing and maintaining data pipelines, ensuring that the data was accurate and properly structured from different sources. Abrar is an exceptional problem solver with great attention to detail, making him an asset in any data analysis team. With his dedication and expertise, Abrar is sure to exceed expectations in any data analysis role he takes on."
  },
  {
    avatar: AVTR4,
    name: 'Yousef Mohammad',
    review: "Abrar excelled in his Naive Bayes NLP research project for the $20M information extraction industry. He produced an outstanding 10-page report and was an excellent team player. He's definitely set up for success!"
  },
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from coworkers and supervisors</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]} spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials