import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/port0.jpg'
import IMG2 from '../../assets/port1.jpg'
import IMG3 from '../../assets/port4.jpg'
import IMG4 from '../../assets/port6.jpg'
import IMG5 from '../../assets/port8.jpg'
import IMG6 from '../../assets/port9.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Utilized Swift and Google firebase to build FirePit, a matching app with messaging features',
    github: 'https://github.com/ahawk32/UGA-Hackathon',
    demo: 'https://youtu.be/8ulZ5gVmTMI'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Developed a Note tracker application with React and JSX',
    github: 'https://github.com/ahawk32/Abrar-Note-Keeper-App',
    demo: 'https://keeperapp.cloud/'
  },
  {
    id: 3,
    image: IMG4,
    title: 'Deployed InceptionV3 ML model to develop a image recognitioning iOS application with Swift',
    github: 'https://github.com/ahawk32/SeeFood',
    demo: true
  },
  {
    id: 4,
    image: IMG3,
    title: 'Developed Pong game with Python and pygame libraries',
    github: 'https://github.com/ahawk32/Abrar-Pong-Game.git',
    demo: null
  },
  {
    id: 5,
    image: IMG5,
    title: 'Built numerous aesthetically pleasing and visually stunning landing pages for early stage startups in the ATL area',
    github: null,
    demo: 'https://abrarpong.online/'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Python Web Scrapper Utilizing Twitter and Yahoo APIs to determine sentiment of given stock',
    github: null,
    demo: true
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2 style={{ fontSize: '2.0rem' }}>Portfolio</h2>


      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  {
                    github === null
                      ? null
                      : <a href={github} className='btn' target='_blank'>Github</a>
                  }
                  {
                    demo === null
                      ? null
                      : <a href={demo === true ? '#contact' : demo} className='btn btn-primary' target={demo === true ? '_self' : '_blank'}>{demo === true ? 'Request Demo' : 'Live Demo'}</a>
                  }
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}




{/*const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo ? demo : '#'} className='btn btn-primary' target='_blank'>{demo ? 'Live Demo' : 'Request Demo'}</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}*/}


export default Portfolio